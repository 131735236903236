.content__partenaire{
    display: flex;
    justify-content: space-between;
    transition: all 2s linear 2s;
 
}
.partenaire__slide{
    padding: 3px;
    border-radius: 5px;
    background-color: var(--bgwhite-color);
    box-shadow: var(--shadow);
    display: flex;
    justify-content: center;
}

.partenaire__slide:hover {
    transform: scale(1.1);
  }
/* *********************************************** */
   /* responsive */
/* *********************************************** */
@media (max-width: 1023px) {
    .container{
        padding-left: 50px;
        padding-right: 50px;
        }
}
@media (max-width: 960px) {
 .container{
 padding-left: 50px;
 padding-right: 50px;
 }
 .content__partenaire{
    justify-content: space-between;
}
}
@media (max-width: 720px) {
   
        .container{
            padding-left: 50px;
            padding-right: 50px;
        }
        .content__partenaire img{
            width: 80px;
            height: 50px;
        }
}
@media (max-width: 520px) {
    .container{
        padding-left: 50px;
        padding-right: 50px;
    }
    .content__partenaire img{
        width: 60px;
        height: 50px;
    }
    .content__partenaire {
     padding-top: 50px;
    }
    
}

@media (max-width: 320px) {
    .partenaire__slide{
        padding: 0px;
        border-radius: 0px;
        background-color: transparent;
        box-shadow: none;
     
    }
    
    .partenaire__slide:hover {
        transform: scale(1);
      }
}

