.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  position: fixed;
  padding-left: 130px;
  padding-right: 130px;
  transition: background 0.5s ease;
  z-index: 30;
  width: 100%;
  height: 5rem;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(29, 29, 29, 0.25);
  position: -webkit-sticky;
  position: fixed;
  top: 0;
}
.line_nav {
  height: 1px;
  background-image: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.3),
    #1d1d1d 45%
  );
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.navbar_white {
  background-color: var(--bgwhite-color);
}

.nav-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 80px;
}

.nav-container:focus {
  background-color: var(--bgwhite-color);
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
  color: #ffffff;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  width: 20%;
  display: flex;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  width: 80%;
  display: flex;
  justify-content: end;
  font-size: 0.9em;
}

.nav-links {
  color: black;
  text-decoration: none;
  padding-right: 30px;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.fa-code {
  margin-left: 1rem;
}

.navbar_transparent div ul li .nav-links {
  color: white;
}

.navbar_transparent div ul li .nav-links:hover {
  color: rgb(218, 214, 214);
}

.navbar_transparent div ul .nav-item:hover:after {
  width: 80%;
  background: whitesmoke;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.2s ease, background-color 0.5s ease;
}

.savoir__faire {
  position: absolute;
  list-style: none;
  width: 200px;
  background-color: var(--bgwhite-color);
  margin-top: 3px;
  transition: width 5s;
  box-shadow: var(--shadow);
}

.savoir__faire li:hover .drop__item::before {
  content: "-";
  font-weight: bold;
  font-size: 0.8em;
}

.drop__item {
  text-decoration: none;
  color: black;
}

.savoir__faire li:hover .drop__item {
  margin-left: 3px;
  color: var(--bgblue-color);
}

#drops {
  display: none;
  transition: display 0.5s;
}

#dropdown:hover #drops {
  display: block;
}

.savoir__faire li {
  width: 100%;
  padding-left: 10px;
  cursor: pointer;
  font-size: 0.8em;
  border-top: 0.1em solid rgba(100, 100, 111, 0.2);
  opacity: 0.9;
  transition: padding-left 0.5s ease;
  text-align: left;
}

.savoir__faire li:hover {
  background-color: var(--bgsmoke-color);
  padding-left: 20px;
  color: var(--bgblue-color);
}
/* .nav-item:hover:after {
  width: 80%;
  background: var(--bgblue-color);
} */

.nav-item.active {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.nav-icon {
  display: none;
}

/* end dropdown menu */

#hamburgerEffect {
  display: none;
}

#hamburgerEffect:checked ~ #meny {
  transform: translateX(0rem);
}

#burger {
  position: absolute;
  cursor: pointer;
  width: 2rem;
  height: 1em;
  right: 2rem;
  top: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 16px;
}

#burger > div {
  height: 2px;
  background-color: var(--bgwhite-color);
  transition: 0.5s;
  z-index: 999;
}

#hamburgerEffect:checked ~ #burger > div {
  background-color: var(--bgwhite-color);
}

#hamburgerEffect:checked ~ #burger > div:nth-child(1) {
  transform: translateY(13px) rotate(45deg);
}

#hamburgerEffect:checked ~ #burger > div:nth-child(2) {
  opacity: 0;
}

#hamburgerEffect:checked ~ #burger > div:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}

.drop_button {
  display: none;
}

@media screen and (max-width: 1023px) {
  .navbar {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 0.9em;
  }
}

@media screen and (max-width: 960px) {
  .navbar {
    margin-top: 0px;
  }

  .nav-container {
    background: rgba(0, 0, 0);
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    height: 100vh;
  }

  .nav-menu.active {
    background: rgba(0, 0, 0, 0.9);
    height: 100vh;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: #ffffff;
    border: none;
  }

  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
    color: white;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ffffff;
  }
  .nav-links {
    color: var(--bgwhite-color);
    font-size: var(--smaller-font-size);
  }
  .nav-item:hover:after {
    width: 40%;
    background: var(--bgwhite-color);
  }

  #dropdown:hover #drops {
    display: none;
  }
}

@media (max-width: 520px) {
  .navbar {
    margin-top: 0px;
  }

  .nav-menu {
    /* background: var(--bgblue-color); */
    top: 60px;
    height: 100%;
    padding-bottom: 200px;
  }
  .navbar {
    background: var(--bgwhite-color);
    padding-left: 0px;
    padding-right: 0px;
    height: 60px;
    display: flex;
  }
  .nav-logo img {
    width: 40px;
    height: 40px;
  }
  .nav-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-icon {
    display: flex;
    align-items: center;
  }
  .nav-links {
    color: var(--bgwhite-color);
    font-size: var(--smaller-font-size);
  }
  .nav-item:hover:after {
    width: 40%;
    background: var(--bgwhite-color);
  }
  #deroule {
    margin: auto;
  }
  #deroule img {
    width: 20px;
    height: 20px;
    top: 20px;
  }
  .nav-item {
    line-height: 15px;
  }
  #dropdown:hover #drops {
    display: none;
  }
  .nav-item:hover .nav-links {
    color: var(--bgwhite-color);
  }
}
