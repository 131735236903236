.active1{
    padding-left: 230px;
    padding-right: 230px;
    padding-top: 70px;
    padding-bottom: 70px;
    margin: 0 auto;
}
.activity{
    display: flex;
    gap: 40px;
}
h2{
    color: var(--title-color);
}
.active1{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
@media (max-width: 1023px) {
    .active1{
        padding-left: 50px;
        padding-right: 50px;
    } 
}

@media (max-width: 960px) {
    .active1{
        padding-left: 50px;
        padding-right: 50px;
    }
    .activity{
        display: grid;
       grid-template-columns: repeat(2, 300px);
        gap: 20px;
        margin: auto;
    }
}
@media (max-width: 720px) {
    .active1{
        padding-left: 50px;
        padding-right: 50px;
    }
    .activity{
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
    }
}
@media (max-width: 520px) {
    .active1{
        padding-left: 30px;
        padding-right: 30px;
    }
    .activity{
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
    }

    .active1 h2{
        font-size:0.9em ;
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
    }
}

