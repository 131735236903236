footer {
  position: relative;
  background: url(../../assets/map.png);
  /* background-color: var(--bgblue-color); */
  background-color: "black";
  width: 100%;
  bottom: 0;
  left: 0;
}

footer::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100px;
  height: 1px;
  width: 100%;
}
footer .content {
  max-width: 1250px;
  margin: auto;
  padding: 30px 40px 60px 40px;
}
footer .content .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.content .top .logo-details {
  color: #2e2727;
  font-size: 30px;
}
.content .top .media-icons {
  display: flex;
}
.content .top .media-icons a {
  height: 40px;
  width: 40px;
  margin: 0 8px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #3e3939;
  font-size: 17px;
  text-decoration: none;
  transition: all 0.4s ease;
}
.top .media-icons a:nth-child(1) {
  background: #4267b2;
}
.top .media-icons a:nth-child(1):hover {
  color: #4267b2;
  background: #0b214a;
}
.top .media-icons a:nth-child(2) {
  background: #1da1f2;
}
.top .media-icons a:nth-child(2):hover {
  color: #1da1f2;
  background: #0b214a;
}
.top .media-icons a:nth-child(3) {
  background: #e1306c;
}
.top .media-icons a:nth-child(3):hover {
  color: #e1306c;
  background: #0b214a;
}
.top .media-icons a:nth-child(4) {
  background: #0077b5;
}
.top .media-icons a:nth-child(4):hover {
  color: #0077b5;
  background: #0b214a;
}
.top .media-icons a:nth-child(5) {
  background: #ff0000;
}
.top .media-icons a:nth-child(5):hover {
  color: #ff0000;
  background: #0b214a;
}
footer .content .link-boxes {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
footer .content .link-boxes .box {
  width: calc(100% / 5 - 10px);
}
.content .link-boxes .box .link_name {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
  position: relative;
}
.link-boxes .box .link_name::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 55px;
  background: #ece8ec;
}
.content .link-boxes .box li {
  margin: 6px 0;
  list-style: none;
}
.content .link-boxes .box li a {
  color: #f9f8f8;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.3s ease;
}
.content .link-boxes .box li a:hover {
  opacity: 1;
  margin-left: 6px;
}
.content .link-boxes .input-box {
  margin-right: 55px;
}

footer .bottom-details {
  width: 100%;
}
footer .bottom-details .bottom_text {
  max-width: 1250px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}
.bottom-details .bottom_text a:hover {
  opacity: 1;
  text-decoration: underline;
}
.bottom-details .bottom_text a {
  margin-right: 10px;
}
@media (max-width: 900px) {
  footer .content .link-boxes {
    flex-wrap: wrap;
  }
  footer .content .link-boxes .input-box {
    width: 40%;
    margin-top: 10px;
  }
}
@media (max-width: 700px) {
  footer {
    position: relative;
  }
  .content .top .logo-details {
    font-size: 26px;
  }
  .content .top .media-icons a {
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }
  footer .content .link-boxes .box {
    width: calc(100% / 3 - 10px);
  }
  footer .content .link-boxes .input-box {
    width: 60%;
  }
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a {
    font-size: 12px;
  }
}
@media (max-width: 520px) {
  footer::before {
    top: 145px;
  }
  footer .content .top {
    flex-direction: column;
  }
  .content .top .media-icons {
    margin-top: 16px;
  }
  footer .content .link-boxes .box {
    width: calc(100% / 2 - 10px);
  }
  footer .content .link-boxes .input-box {
    width: 100%;
  }
}
