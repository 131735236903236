.news {
  background-color: var(--bgsmoke-color);
  display: flex;
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 70px;
  padding-bottom: 70px;
  /* margin: 0 auto; */
}
.news__left {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.new__text {
  color: var(--title-color);
  font-weight: 600;
  font-size: var(--h1-font-size);
}
.news__left p {
  margin-top: 20px;
  color: var(--header-color);
}
.news__right {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.news__input {
  width: 100%;
  padding: 3px;
  border-radius: 5px;
  display: flex;
  border: 1px solid rgba(100, 100, 111, 0.3);
  background-color: var(--bgwhite-color);
  outline: 0.1px solid #7517cc;
}
.news__input input {
  width: 60%;
  border: none;
  outline: none;
  padding-left: 10px;
}

.btn2 {
  border-radius: 10px;
  width: 40%;
}

/* *********************************************** */
/* responsive */
/* *********************************************** */
@media (max-width: 1023px) {
  .news {
    padding-left: 50px;
    padding-right: 50px;
  }
  .news__input {
    height: 80px;
    font-size: 0.7em;
  }
  .news__left p {
    margin-top: 20px;
    font-size: 0.8em;
  }
  .new__text {
    font-size: var(--h2-font-size);
  }
}
@media (max-width: 960px) {
  .news {
    display: block;
  }
  .news__left {
    width: 100%;
  }
  .news__right {
    width: 100%;
  }
  .news {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 720px) {
  .news {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
  .news__left {
    width: 100%;
  }
  .news__right {
    width: 100%;
  }
}
@media (max-width: 520px) {
  .news {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .news__left {
    width: 100%;
    padding: 0px;
  }
  .news__right {
    width: 100%;
    padding: 0px;
  }
  .news__input {
    width: 100%;
    padding: 0px;
    margin-top: 20px;
    height: 60px;
  }
  .news__input button {
    font-size: 0.5em;
    padding: 10px;
  }
}
