/* Tailwind css styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ================= Google FOnts ====================== */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap'); */

/* ============== VARIABLE GLOBALE ==================== */
:root {
  /* COLORS TO BE USED */
  --bgblue-color: #800080;
  /* --bgblue-color: linear-gradient(315deg, rgb(153, 0, 255) 0%, rgb(0, 136, 255) 100%);   */
  --bgwhite-color: #ffffff;
  --bgsmoke-color: #f7faff;
  --title-color: #0b214a;
  --body-color: #0f4f4f;
  --page-header-color: #ffffff;
  --page-color: #eaff58;
  --header-color: #0f4f4f;
  --color-text: #1e2637;
  --color-subtext: #6e6e6e;

  /* FONT FAMILY AND FONT SIZE */
  --body-font: "Poppins", sans-serif;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;

  /* SHADOW A UTILISE */
  --shadow: 0 0 12px 0 rgb(32 46 60 / 6%);
}

/* ===========STYLE DE BASE ==================== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--body-font);
}
body {
  min-height: 100vh;
  width: 100%;
  background-color: var(--bgwhite-color);
  box-sizing: border-box;
  font-family: var(--body-font);
}

.section__seperator {
  height: 60px;
  background: transparent;
  padding: 10px;
}

.container {
  padding-left: 230px;
  padding-right: 230px;
  padding-top: 70px;
  padding-bottom: 70px;
  margin: 0 auto;
}

.container_custom {
  padding-inline: 130px;
  padding-bottom: 70px;
  margin: 0 auto;
}

.grid {
  display: grid;
}

.shadow {
  box-shadow: var(--shadow);
}

.container__fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.section__white {
  padding-top: 7rem;
  padding-bottom: 1rem;
  background-color: var(--bgwhite-color);
}

.section__smoke {
  padding-top: 7rem;
  padding-bottom: 1rem;
  background-color: var(--bgsmoke-color);
}

.section__blue {
  padding-top: 7rem;
  padding-bottom: 1rem;
  background-color: var(--bgblue-color);
}

/* Pour le button avec arrière plan blue et text en blanc */
.btn {
  padding: 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.btn3 {
  border: none;
  border-radius: 30px;
  cursor: pointer;
  padding: 10px;
  width: 100px;
  font-size: 0.6em;
}

.btn4 {
  border: none;
  border-radius: 30px;
  cursor: pointer;
  padding: 10px;
}

.btn__primary {
  color: var(--bgwhite-color);
  background: var(--bgblue-color);
}

.btn__primary:hover {
  opacity: 0.7;
  transition: all 1s ease;
}

.btn__primary1 {
  border: 1px solid var(--bgwhite-color);
  color: var(--bgwhite-color);
  background: var(--bgblue-color);
}

.btn__primary1:hover {
  color: var(--bgblue-color);
  background: var(--bgwhite-color);
  transition: all 1s ease;
}

/* On the firs section */
.btn__primary2 {
  color: var(--bgwhite-color);
  background: var(--bgblue-color);
  width: 150px;
}

.btn__primary2:hover {
  background-color: transparent;
  border: 1px solid var(--bgblue-color);
  color: var(--bgblue-color);
  transition: all 0.3s ease;
}

.btn__outline {
  border: 1px solid var(--bgblue-color);
  color: var(--bgblue-color);
  background: transparent;
  width: 150px;
}
.btn__outline:hover {
  color: var(--bgwhite-color);
  background: var(--bgblue-color);
  transition: all 0.3s ease;
}

/* Pour le button avec arrière plan blanc et text en bleu */
.btn__white {
  color: var(--bgblue-color);
  background-color: var(--bgwhite-color);
  font-weight: 500px;
}

.btn__white:hover {
  color: var(--bgwhite-color);
  background-color: transparent;
  border: 1px solid white;
  transition: all 1s ease;
}

.lien {
  padding: 5px;
  border: 1px solid black;
}

@media screen and (max-width: 960px) {
  .container_custom {
    padding-inline: 10px;
  }
}
