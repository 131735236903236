.not__found{
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.not__found .error_text{
    font-size: 35px;
    font-weight: 500;
}


  @media (max-width: 720px) {
    .not__found{
        height: 400px;
    
    }
 
    .not__found img{
        width: 250px;
        height: 250px;
    }

  }
  @media (max-width: 520px) {
    .not__found{
        height: 500px;
    
    }
    
 
    .not__found img{
        width: 300px;
        height: 300px;
    }

    
}