.processus {
  width: 100%;
  padding-top: 50px;
  background-color: var(--bgsmoke-color);
  padding-top: 50px;
  padding-bottom: 50px;
}
.processus__imag {
  position: absolute;
}
.content__card__image {
  position: relative;
}

.ligne {
  position: absolute;
  width: 40%;
  height: 20%;
  right: 3px;
  transform: translateX(45px);
  margin-top: 80px;
}

.processus__content {
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 50px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.processus__title h2 {
  text-align: center;
  color: var(--color-text);
}

@media screen and (max-width: 1023px) {
  .ligne {
    position: absolute;
    width: 40%;
    height: 20%;
    right: 3px;
    transform: translateX(33px);
    margin-top: 70px;
  }
}

@media screen and (max-width: 960px) {
  .ligne {
    display: none;
  }
}

@media (max-width: 720px) {
  .processus__content {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 520px) {
  .processus__content {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
  }
  .processus__title h2 {
    font-size: 0.9em;
  }
}
