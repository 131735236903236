.blog{
 padding-left: 50px;
 padding-right: 50px;
 padding-bottom: 50px;
 display: grid;
 grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 960px) {
    .blog{
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
       }
  }
  
  @media (max-width: 720px) {
    .blog{
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
       }
  }
  
  @media (max-width: 520px) {
    .blog{
        grid-template-columns: repeat(1, 1fr);
        column-gap: 20px;
        padding-left: 20px;
        padding-right: 20px;
       }
  }
  @media (max-width: 320px) {
    .blog{
        grid-template-columns: repeat(1, 1fr);
        column-gap: 20px;
        padding-left: 5px;
        padding-right: 5px;
       }
  }