/* *********************************************** */
/* Service card */
/* *********************************************** */
.service_card {
  background: linear-gradient(270deg, #a100ff 5px, transparent 0) 100% 0,
    linear-gradient(180deg, #a100ff 5px, transparent 0) 100% 0;
  background-repeat: no-repeat;
  background-size: 56px 40px;

  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.16) 2px 3px 8px;
  height: auto;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(239, 239, 239);
  border-image: initial;
  overflow: hidden;
}

.service_card:hover {
  color: white !important;
}

.service_card:hover div span {
  background-position: 100% 100%;
  color: white !important;
}

/* *********************************************** */
/* responsive */
/* *********************************************** */

/* ***** content title page */
.content__title2 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bgblue-color);
  padding-top: 30px;
  padding-left: 230px;
  padding-right: 230px;
  padding-bottom: 20px;
  margin: 0 auto;
}

.content__title2 h2 {
  color: var(--bgwhite-color);
  margin-top: 10px;
  font-weight: 600;
}

.header__text {
  font-size: var(--h2-font-size);
}

.content__title2 .contents__text {
  position: absolute;
  width: 400px;
}

.content__title2 .contents__text p {
  color: var(--bgwhite-color);
}

.container__title h2 {
  font-weight: 600;
  color: var(--color-text);
}

.content__title2 h2 {
  color: var(--bgwhite-color);
  margin-top: 10px;
  font-weight: 600;
}

.content__img {
  width: 100px;
  transform: translateX(-140px);
}

.container__body {
  max-width: 400px;
  text-align: center;
  margin: auto;
}

@media (max-width: 1023px) {
  .content__img {
    display: none;
  }
}

@media (max-width: 960px) {
  .content__title {
    padding-left: 50px;
    padding-right: 50px;
  }

  .content__title .content__img {
    display: none;
  }

  .content__title2 {
    padding-left: 50px;
    padding-right: 50px;
    display: none;
  }
}

@media (max-width: 720px) {
  .content__title .content__img {
    display: none;
  }

  .content__title2 .content__img {
    display: none;
  }

  .content__title {
    padding-left: 50px;
    padding-right: 50px;
  }

  .content__title2 {
    padding-left: 50px;
    padding-right: 50px;
    display: none;
  }

  .container__title {
    width: 100%;
  }

  .contents__text {
    width: 100%;
    transform: translateX(0px);
  }

  .content__img {
    width: 0px;
    transform: translateX(-0px);
  }
}

@media (max-width: 520px) {
  .content__title .content__img {
    display: none;
  }

  .content__title2 .content__img {
    display: none;
  }

  .header__text {
    font-size: 1em;
  }

  .content__title2 .contents__text p {
    font-size: 0.6em;
  }

  .content__title .contents__text p {
    font-size: 0.6em;
  }

  .content__title {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0;
  }

  .content__title2 {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    margin: 0;
    display: none;
  }

  .container__title {
    width: 100%;
  }

  .container__body {
    width: 100%;
  }

  .contents__text {
    width: 100%;
    transform: translateX(0px);
  }

  .content__img {
    width: 0px;
    transform: translateX(-0px);
  }
}

@media (max-width: 320px) {
  .content__title {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 50px;
    padding-bottom: 70px;
    margin: 0;
  }
}

/* ************************************************** */
/* service title */
/* ************************************************** */

.services_title {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 230px;
  padding-right: 230px;
  padding-top: 70px;
  padding-bottom: 70px;
  color: var(--header-color);
}

@media (max-width: 960px) {
  .services_title {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;
  }

  .content__img {
    display: none;
  }

  .contents__text {
    position: absolute;
  }
}

@media (max-width: 720px) {
  .services_title {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (max-width: 520px) {
  .services_title {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

/* ***************************************************************** */
/*
       style du composant du blog  contact  
 */
/* ********************************************************************** */

.content__contact {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
}

.contact__icon {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 100%;
  /* background-color: var(--bgwhite-color); */
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact__text {
  display: flex;
  align-items: center;
}

/* .contact__text p {
  color: var(--bgwhite-color);

} */

@media (max-width: 960px) {
  .contact__text p {
    font-size: 0.7em;
  }
}

@media (max-width: 720px) {
  .contact__text p {
    font-size: 0.7em;
  }
}

@media (max-width: 520px) {
  .contact__icon {
    width: 35px;
    height: 35px;
  }

  .contact__text p {
    font-size: 0.7em;
  }
}

@media (max-width: 290px) {
  .contact__icon {
    width: 35px;
    height: 35px;
  }

  .contact__text p {
    font-size: 0.5em;
  }
}

/*  ** **************************** *********** */
/* component input */
/* ********************************************* */

.input__image {
  border-radius: 10px;
  padding: 18px;
  width: 300px;
  border: 1px solid rgba(100, 100, 111, 0.2);
}

.input__image:focus {
  outline: 0.1px solid #7517cc;
  opacity: 0.3;
}

.imageinput {
  margin-top: 18px;
  margin-left: -35px;
  width: 20px;
  height: 20px;
  position: absolute;
}

@media (max-width: 960px) {
  .imageinput {
    width: 300px;
    position: absolute;
    transform: translateY(-50px);
    transform: translateX(-30px);
    margin-top: 10px;
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 720px) {
  .imageinput {
    width: 250px;
    position: absolute;
    transform: translateY(-50px);
    transform: translateX(-10px);
    margin-top: 20px;
    width: 20px;
    height: 20px;
  }

  .input__image {
    padding: 18px;
    width: 300px;
  }
}

@media (max-width: 520px) {
  .imageinput {
    width: 250px;
    position: absolute;
    transform: translateY(-50px);
    transform: translateX(-10px);
    margin-top: 20px;
    width: 20px;
    height: 20px;
  }

  .input__image {
    padding: 18px;
    width: 300px;
  }
}

@media (max-width: 320px) {
  .imageinput {
    width: 250px;
    position: absolute;
    transform: translateY(-50px);
    transform: translateX(-30px);
    margin-top: 10px;
    width: 20px;
    height: 20px;
  }

  .input__image {
    padding: 18px;
    width: 250px;
  }
}

/* ********************************************************** */
/* composant mission  */
/* ********************************************************** */

.mission {
  display: flex;
  justify-content: space-between;
  background-color: var(--bgwhite-color);
  padding-left: 150px;
  padding-right: 150px;
}

.mission2 {
  display: flex;
  justify-content: space-between;
  background-color: var(--bgsmoke-color);
  padding-left: 150px;
  padding-right: 150px;
}

.mission__left {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.card__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 450px;
  border-radius: 30px;
  box-shadow: var(--shadow);
  background-color: var(--bgwhite-color);
}

.mission__right {
  width: 48%;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-direction: column;
  gap: 30px;
  background-color: transparent;
}

.mission__header h3 {
  color: var(--title-color);
  text-align: justify;
}

.mission__subtitle p {
  color: var(--header-color);
  line-height: 30px;
  text-align: justify;
}

.chec__icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bgblue-color);
  border-radius: 50%;
}

.mission__header__chec {
  display: flex;
  gap: 10px;
}

@media (max-width: 1023px) {
  .mission__subtitle p {
    font-size: 0.9em;
    line-height: 20px;
  }

  .card__image {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 960px) {
  .mission__subtitle p {
    font-size: 0.9em;
    line-height: 20px;
  }

  .card__image {
    width: 300px;
    height: 300px;
  }

  .mission {
    display: block;
  }

  .mission2 {
    display: block;
  }

  .mission__left {
    width: 100%;
  }

  .mission__right {
    width: 100%;
  }
}

@media (max-width: 720px) {
  .mission {
    display: block;
  }

  .mission2 {
    display: block;
  }

  .mission__left {
    width: 100%;
  }

  .mission__right {
    width: 100%;
  }

  .mission__subtitle p {
    text-align: left;
  }
}

@media (max-width: 520px) {
  .mission {
    display: block;
    background-color: var(--bgwhite-color);
    padding-left: 50px;
    padding-right: 50px;
  }

  .mission2 {
    display: block;
    padding-left: 50px;
    padding-right: 50px;
  }

  .mission__left {
    width: 100%;
  }

  .mission__right {
    width: 100%;
    padding-left: 30px;
  }

  .card__image {
    width: 450px;
    height: 250px;
  }

  .card__image img {
    width: 200px;
    height: 200px;
  }

  .mission__subtitle p {
    text-align: left;
  }
}

/* ********************************************************************* */
/* pour le blog valeur */
/* ******************************************************************* */

.valeur {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 230px;
  padding-right: 230px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.blog__text h4 {
  font-weight: 500;
}

@media (max-width: 960px) {
}

@media (max-width: 720px) {
  .valeur {
    padding-left: 50px;
    padding-right: 50px;
  }

  .valeur img {
    width: 400px;
    height: 300px;
  }
}

@media (max-width: 520px) {
  .valeur {
    padding-left: 50px;
    padding-right: 50px;
  }

  .valeur img {
    width: 300px;
    height: 200px;
  }
}

/* ***************************************** */
/* detail du service de transformation */
/* ****************************************** */

.service__transformation {
  display: flex;
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 70px;
  padding-bottom: 70px;
  margin: 0 auto;
}

.transformation__image {
  width: 50%;
}

.transformation__text {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.transformation__text .mission__subtitle {
  text-align: justify;
}

.transformation__text .mission__header {
  text-align: justify;
}

.transformation__text .p2 {
  color: var(--title-color);
}

.transformation__text .mission__header .p2 {
  color: var(--title-color);
}

.transformation__text .p2 {
  margin-top: 20px;
  font-weight: 400;
  line-height: 25px;
}

@media (max-width: 1023px) {
  .service__transformation {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 960px) {
  .service__transformation {
    display: block;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }

  .transformation__image {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .transformation__image img {
    width: 350px;
    height: 350px;
  }

  .transformation__text {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 720px) {
  .service__transformation {
    display: block;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }

  .transformation__image {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .transformation__image img {
    width: 200px;
    height: 200px;
  }

  .transformation__text {
    width: 100%;
    margin-top: 10px;
  }

  .transformation__text img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 520px) {
  .service__transformation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0px;
  }

  .transformation__image {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .transformation__image img {
    width: 200px;
    height: 200px;
  }

  .transformation__text {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .transformation__text p {
    line-height: 1.6em;
    text-align: left;
  }

  .transformation__text h2 {
    line-height: 1.6em;
    text-align: left;
    font-size: 0.9em;
  }

  .transformation__text img {
    width: 25px;
    height: 25px;
  }
}

/* **************************************** */
/* blog vision */
/* **************************************** */

.vision {
  background: var(--bgblue-color);
  display: flex;
  flex-direction: column;
}

.content__vision {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.vision__image {
  margin-top: 10px;
  margin: auto;
}

.vision__text p {
  color: var(--bgwhite-color);
  font-size: var(--h3-font-size);
}

@media (max-width: 1023px) {
  .vision .vision__image img {
    width: 600px;
    height: 400px;
  }

  .vision__text p {
    font-size: 0.8em;
  }

  .content__img {
    display: none;
  }
}

@media (max-width: 960px) {
  .vision {
    padding-left: 20px;
    padding-right: 20px;
  }

  .vision__text p {
    font-size: var(--smaller-font-size);
  }

  .vision__image img {
    width: 500px;
    height: 300px;
  }
}

@media (max-width: 720px) {
  .vision {
    padding-left: 20px;
    padding-right: 20px;
  }

  .vision__text p {
    font-size: var(--smaller-font-size);
  }

  .content__img {
    display: none;
  }

  .vision__image img {
    width: 400px;
    height: 300px;
  }
}

@media (max-width: 520px) {
  .vision {
    padding-left: 20px;
    padding-right: 20px;
  }

  .vision__text p {
    font-size: 0.5em;
  }

  .content__img {
    display: none;
  }

  .vision .vision__image img {
    width: 200px;
    height: 150px;
  }
}

/* ============================================================ */
/* Speccific Service Card */
/* ============================================================ */
.specific_service__card {
  background: var(--bgwhite-color);
  padding-top: 20px;
  padding-left: 50px;
  display: flex;
  height: 370px;
  box-shadow: var(--shadow);
  margin: auto;
}

.specific_service__card .service_text {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.specific_service__card .service1__text p {
  color: var(--title-color);
  padding-top: 10px;
  line-height: 20px;
  font-weight: 300;
  font-size: 0.9em;
}

.specific_service__card .service__image p {
  color: var(--bgwhite-color);
}

.specific_service__card .service__image {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.specific_service__card .service__image .service__text {
  height: 75px;
  width: 60px;
  background-color: var(--bgblue-color);
  border-top-left-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

@media screen and (max-width: 1023px) {
  .specific_service__card {
    background: var(--bgwhite-color);
    width: 220px;
    height: 300px;
    margin-top: 30px;
    padding-left: 25px;
  }

  .specific_service__card .service1__text p {
    color: var(--title-color);
    padding-top: 10px;
    line-height: 17px;
    font-weight: 300;
    font-size: 0.6em;
  }

  .specific_service__card .service__image .service__text {
    height: 50px;
    width: 49px;
    font-size: 15px;
  }

  .specific_service__card .service__image img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 960px) {
  .specific_service__card {
    background: var(--bgwhite-color);
    width: 300px;
    height: 300px;
    margin-top: 30px;
    padding-left: 25px;
  }

  .specific_service__card .service1__text p {
    color: var(--title-color);
    padding-top: 10px;
    line-height: 20px;
    font-weight: 300;
    font-size: 0.7em;
  }

  .specific_service__card .service__image .service__text {
    height: 50px;
    width: 49px;
    font-size: 15px;
  }

  .specific_service__card .service__image img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 720px) {
  .specific_service__card {
    background: var(--bgwhite-color);
    width: 310px;
    height: 300px;
    margin-top: 30px;
  }

  .specific_service__card .service__image .service__text {
    height: 75px;
    width: 65px;
  }

  .specific_service__card .service1__text p {
    line-height: 20px;
    font-size: 0.8em;
  }
}

@media (max-width: 520px) {
  .specific_service__card {
    background: var(--bgwhite-color);
    width: 290px;
    height: 300px;
    margin-top: 30px;
  }

  .specific_service__card .service__image .service__text {
    height: 75px;
    width: 60px;
  }

  .specific_service__card .service1__text p {
    line-height: 20px;
    font-size: 0.8em;
  }
}

@media (max-width: 320px) {
  .specific_service__card {
    background: var(--bgwhite-color);
    width: 250px;
    height: 280px;
    margin-top: 30px;
  }

  .specific_service__card .service__image .service__text {
    height: 75px;
    width: 60px;
  }

  .specific_service__card .service1__text p {
    line-height: 17px;
    font-size: 0.7em;
  }
}

/* ******************************************** */
/* card des activites de l'entreprise */
/* ******************************************** */

.card__activity {
  height: 330px;
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
  justify-content: center;
  align-items: center;
  transition: transform 2s ease;
  cursor: pointer;
  border-radius: 5px;
}

.card__activity:hover {
  transform: scale(1.1);
}

.card__activity .first {
  height: 20%;
}

.card__activity .second {
  height: 80%;
}

.second__text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.second__text p {
  color: var(--header-color);
  font-size: 0.9em;
  text-align: center;
}

.second__text h3 {
  color: var(--header-color);
  font-size: var(--normal-font-size);
}

@media (max-width: 960px) {
  .card__activity {
    height: 300px;
    width: 300px;
    margin: auto;
  }
}

@media (max-width: 720px) {
  .card__activity {
    margin: auto;
  }
}

@media (max-width: 520px) {
  .card__activity {
    height: 250px;
    width: 250px;
    margin: auto;
  }
}

/* ************************************************* */
/* composant du blog */
/* ************************************************* */

.blog__card {
  height: 350px;
  border: 1px solid rgba(100, 100, 111, 0.2);
  box-shadow: var(--shadow);
  position: relative;
  width: 350px;
  cursor: pointer;
  margin: auto;
}

.blog__card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.opacite {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
  transition: background-color 2s ease;
  display: flex;
  align-items: flex-end;
}

.content__blog {
  height: 50%;
  transform: translateY(0px);
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 1s ease;
  position: absolute;
  padding: 15px;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.content__blog .lire {
  padding: 10px;
  width: 100px;
  border: 1px solid white;
  color: white;
  text-decoration: none;
  text-align: center;
}

.content__blog .blog__card__title {
  font-size: var(--h3-font-size);
  text-align: center;
  color: var(--bgwhite-color);
}

.content__blog .lire:hover {
  background-color: var(--bgblue-color);
  border: none;
}

.opacite:hover .content__blog {
  transform: translateY(-105px);
}

@media (max-width: 320px) {
  .blog__card {
    height: 250px;
    width: 250px;
  }

  .content__blog a {
    padding: 10px;
    font-size: 0.8em;
  }

  .content__blog p {
    font-size: 0.8em;
  }
}

/* ============================================================ */
/* Testimonial Card */
/* ============================================================ */
.testimonials__card {
  background: var(--bgwhite-color);
  text-align: center;
  color: var(--title-color);
  padding: 2em 1em;
  width: 60%;
  height: 270px;
  border-radius: 1em;
}

.testimonials__card img {
  width: 5em;
  height: 5em;
  border: 5px solid var(--bgwhite-color);
  border-radius: 50%;
  margin-top: -4.5em;
}

@media (max-width: 960px) {
}

@media (max-width: 720px) {
  .testimonials__card blockquote {
    font-size: 0.8em;
    margin: 0px;
  }

  .testimonials__card cite {
    font-size: 0.9em;
  }
}

@media (max-width: 520px) {
  .testimonials__card {
    width: 500px;
    height: 200px;
    border: 1px solid var(--bgblue-color);
    padding: 20px;
  }

  .testimonials__card blockquote {
    font-size: 0.7em;
    margin: 0px;
  }

  .testimonials__card cite {
    font-size: 0.9em;
  }

  .testimonials__card img {
    width: 3em;
    height: 3em;
  }
}

/* ********************************************* */
/* button__fixe */
/* ********************************************* */

.button__fixe {
  box-shadow: var(--shadow);
  /* position: fixed;
  bottom: 1px;
  z-index: 18;
  right: 26px; */
  animation: bounce 2s infinite;
  border-radius: 5px;
}

.button__fixe:hover {
  background-color: darkslategrey;
  transition: all 1s ease;
}

@keyframes bounce {
  0% {
    bottom: 100px;
  }

  50% {
    bottom: 105px;
  }

  100% {
    bottom: 100px;
  }
}

@media (max-width: 960px) {
}

@media (max-width: 720px) {
  .button__fixe {
    width: 45px;
    height: 45px;
  }

  .button__fixe img {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 520px) {
  .button__fixe {
    width: 45px;
    height: 45px;
    right: 37px;
  }

  .button__fixe img {
    width: 15px;
    height: 15px;
  }
}

/* ******************************************************************** */
/* section__small */
/* **************************************************************** */
.section__small {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../assets/bacdigi.avif) no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 350px;
}

.section__small .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 10px;

  position: absolute;
}

.section__small .petit__section__text h1 {
  font-weight: 500;
  font-size: var(--h1-font-size);
  margin-top: 150px;
  color: var(--bgwhite-color);
}

.section__small .petit__section__text h4 {
  color: var(--bgwhite-color);
}

.section__small .petit__section__text .description {
  text-align: center;
  width: 600px;
  color: var(--bgwhite-color);
}

.petit__section__img {
  position: absolute;
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.petit__section__text {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
}

/* .section__small .bottom {
  height: 100px;
  width: 100%;
  background: var(--bgblue-color);
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  border: none;
} */

@media (max-width: 960px) {
  .petit__section__img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .section__small .top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .section__small .petit__section__text p {
    text-align: center;
    width: 350px;
  }
}

@media (max-width: 720px) {
  .section__small {
    height: 350px;
  }

  .section__small .bottom {
    display: none;
  }

  .section__small .top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .section__small .top h1 {
    margin-top: 0px;
  }

  .petit__section__img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .section__small .petit__section__text .description {
    text-align: center;
    width: 350px;
  }
}

@media (max-width: 520px) {
  .section__small {
    height: 290px;
  }

  .section__small .bottom {
    display: none;
  }

  .section__small .petit__section__text .description {
    text-align: center;
    width: 300px;
  }

  .section__small .top {
    height: 290px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 25px;
  }

  .section__small .top h1 {
    margin-top: 0px;
    font-size: var(--h3-font-size);
  }

  .section__small .top h4 {
    font-size: var(--smaller-font-size);
  }

  .section__small .petit__section__text h1 {
    font-size: var(--h2-font-size);
    margin-top: 85px;
  }

  .petit__section__img {
    position: absolute;
    height: 290px;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (max-width: 320px) {
  .section__small .petit__section__text .description {
    text-align: center;
    width: 250px;
  }
}

/* ***************************************** */
/* **************************************** */
.name__cite {
  margin-top: 20px;
}

/* ********************************************** */
/* language */
/* ********************************************** */

#deroule {
  position: fixed;
  bottom: 0;
  z-index: 18;
  left: 36px;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.7em;
}

#deroule #menu__drop {
  width: 100px;
  padding: 10px;
  /* background-color: var(--bgblue-color); */
  border: 5px, 0;
  display: flex;
  padding-left: 10px;
  box-shadow: rgba(57, 57, 215, 0.2) 1px 1px 1px 1px;
}

#deroule #menu__drop span {
  color: var(--bgwhite-color);
}

#drop {
  position: absolute;
  list-style: none;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transform: translateY(-140px);
  background-color: var(--bgwhite-color);
  border: 1px solid var(--bgblue-color);
  display: none;
  transition: all 1s ease;
  box-shadow: rgba(57, 57, 215, 0.2) 1px 1px 1px 1px;
}

#drop li {
  width: 100%;
  height: 50%;
  color: var(--bgwhite-color);
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  font-size: 0.8em;
  border-bottom: 1px solid var(--bgblue-color);
}

#drop li:hover {
  background-color: var(--bgblue-color);
  cursor: pointer;
  color: var(--bgwhite-color);
}

#drop li span:hover {
  color: var(--bgwhite-color);
}

#deroule:hover #drop {
  display: block;
}

#deroule span {
  color: var(--bgblue-color);
  font-weight: 500;
  display: flex;
  gap: 5px;
}

#deroule .langue {
  margin-top: 8px;
}

#deroule span img {
  margin-top: 3px;
}

@media (max-width: 960px) {
}

@media (max-width: 720px) {
  #deroule #menu__drop {
    width: 100px;
    padding: 7px;
    font-size: 0.7em;
  }
}

@media (max-width: 520px) {
  #deroule #menu__drop {
    width: 100px;
    padding: 10px;
  }
}

/* ************************************************** */
/* small__blog */
/* ************************************************** */
.small__blog {
  margin-top: 10px;
}

.small__blog h1 {
  font-weight: 600;
  font-size: var(--h3-font-size);
  color: rgba(100, 100, 111, 0.2);
}

.small__blog a {
  padding: 10px;
  border: 1px solid rgba(100, 100, 111, 0.2);
  color: var(--bgblue-color);
  text-decoration: none;
  opacity: 0.8;
  font-weight: 600;
}

.small__blog .links {
  margin-top: 15px;
}

/* ******************************************* */
/* Processus de travail */
/* ****************************************** */

.processus__card {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  margin: auto;
  cursor: pointer;
}

.processus__image {
  height: 70%;
  width: 70%;
  border-radius: 100%;
  border: 3px solid rgba(100, 100, 111, 0.2);
  padding: 10px;
  margin: auto;
  transition: border 0.5s ease;
  box-shadow: var(--shadow);
}

.processus__image img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.processus__number {
  height: 15%;
  width: 40px;
  background-color: var(--color-subtext);
  border-radius: 100%;
  margin: auto;
  transform: translateY(-28px);
  color: var(--bgwhite-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s ease;
}

.processus__card:hover .processus__image {
  border: 3px solid var(--bgblue-color);
}

.processus__card:hover .processus__number {
  background-color: var(--bgblue-color);
}

@media (max-width: 1023px) {
  .processus__card {
    width: 190px;
    height: 190px;
    display: flex;
    flex-direction: column;
    margin: auto;
    cursor: pointer;
  }

  .processus__number {
    height: 15%;
    width: 30px;
  }
}

@media (max-width: 960px) {
  .processus__card {
    width: 250px;
    height: 250px;
  }

  .processus__number {
    height: 15%;
    width: 40px;
  }
}
